// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-page-tsx": () => import("../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-features-index-tsx": () => import("../src/pages/features/index.tsx" /* webpackChunkName: "component---src-pages-features-index-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-page-2-tsx": () => import("../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-pricing-index-tsx": () => import("../src/pages/pricing/index.tsx" /* webpackChunkName: "component---src-pages-pricing-index-tsx" */),
  "component---src-pages-support-index-tsx": () => import("../src/pages/support/index.tsx" /* webpackChunkName: "component---src-pages-support-index-tsx" */),
  "component---src-pages-why-choose-us-index-tsx": () => import("../src/pages/why-choose-us/index.tsx" /* webpackChunkName: "component---src-pages-why-choose-us-index-tsx" */)
}

